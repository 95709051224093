import React, { useState, useRef } from "react";
import queryString from 'query-string';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './App.css';
import ChevronIcon from './assets/chevron.svg';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import { searchPlugin } from '@react-pdf-viewer/search';

// Import styles
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

import CsvViewer from "./Components/CsvViewer";
import MicrosoftViewer from "./Components/MicrosoftViewer";
import HtmlViewer from "./Components/HtmlViewer";
import TxtViewer from "./Components/TxtViewer";
import SearchBar from "./Components/searchInPDF";
export const isIphoneSafari = () => {
  const ua = window.navigator.userAgent || window.navigator.vendor || window.opera;
  const isIphone = /iPhone|iPod|iPad/i.test(ua);
  const isWebKit = /AppleWebKit/i.test(ua);
  const isInApp = isIphone && isWebKit && !/Safari/i.test(ua);
  return isInApp;
};

export const isAndroid = () => {
  const ua = window.navigator.userAgent;
  return /android/i.test(ua);
};
const App = () => {
  const [numPages, setNumPages] = useState(null);
  const [showSearchBar] = useState(true);  
  const searchBarRef = useRef(null); 
  const renderSearchPropsRef = useRef(null); 
  const highlightCurrentMatch = useRef(null);
  const params = queryString.parse(window.location.search);
  const page = params.page && !isNaN(params.page) ? Number(params.page) : 1;
  const [pageNumber] = useState(Number(page));
  const urlObject = new URL(params.url);
  const pathname = urlObject.pathname;
  let fileExtension = pathname.split('.').pop().toLowerCase();
  let modifiedUrl = params.url;
  const documentExtensions = ['doc', 'docx', 'ppt', 'pptx'];
  const videoExtensions = ['mp4', 'webm', 'avi', 'mov', 'quicktime', 'wmv', 'video/x-msvideo', 'video/quicktime'];
  const imageExtensions = ['jpg', 'jpeg', 'png', 'heic'];
  const videoRef = useRef(null);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const zoomPluginInstance = zoomPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const searchPluginInstance = searchPlugin();
  const onDocumentLoadSuccess = (e) => {
    setNumPages(e.doc.numPages);
  };
  const isMobile = isAndroid() || isIphoneSafari();

  const handlePageChange = (e) => {
    const pageNo = document.getElementById('pageNumber');
    if (pageNo) pageNo.innerText = e.currentPage + 1;
  };

  const searchKeyword = async (value) => {
    let numberOfMatches = 0;
    if (value?.length) {
      renderSearchPropsRef?.current?.setKeyword(value);
      await renderSearchPropsRef.current.search().then(resultCount => {
        numberOfMatches = resultCount;
        highlightCurrentMatch.current = resultCount[0];
        window.ReactNativeWebView?.postMessage(`searchResult: ${resultCount?.length}`);
      });
    } else {
      numberOfMatches = 0;
      renderSearchPropsRef?.current?.clearKeyword();
      window.ReactNativeWebView?.postMessage(`searchResult: 0`);
    }
    return numberOfMatches?.length;
  };

  const clearSearch = () => {
    renderSearchPropsRef?.current?.clearKeyword();
  };

  const focusNext = async () => {
    if (renderSearchPropsRef?.current?.currentMatch < renderSearchPropsRef?.current?.numberOfMatches) {
      const nextMatch = renderSearchPropsRef?.current?.currentMatch + 1;
      const res = await renderSearchPropsRef.current.jumpToNextMatch(nextMatch);
      highlightCurrentMatch.current = res;
      return res;
    }
  };

  const focusPrevious = async () => {
    if (renderSearchPropsRef?.current?.currentMatch > 0) {
      const prevMatch = renderSearchPropsRef?.current?.currentMatch - 1;
      const res = await renderSearchPropsRef.current.jumpToPreviousMatch(prevMatch);
      highlightCurrentMatch.current = res;
      return res;
    }
  };

  window.searchKeyword = searchKeyword;
  window.focusNext = focusNext;
  window.focusPrevious = focusPrevious;
  window.clearSearch = clearSearch;

  const pageLayout = {
    buildPageStyles: () => ({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    }),
    transformSize: ({ size }) => ({
      height: size.height,
      width: size.width,
    }),
  };

  const renderViewer = () => {
    if (fileExtension === 'pdf') {
      return (
        <>
          {isMobile && (
              <>
                <searchPluginInstance.Search>
                  {(renderSearchProps) => {
                    renderSearchPropsRef.current = renderSearchProps;
                    return (<></>);
                  }}
                </searchPluginInstance.Search>
              </>
            )}
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <div>
            <div className="document-media-container">
              <Viewer
                plugins={[
                  pageNavigationPluginInstance,
                  zoomPluginInstance,
                  fullScreenPluginInstance,
                  searchPluginInstance,
                ]}
                fileUrl={modifiedUrl}
                defaultScale={window.outerWidth < 1024 ? SpecialZoomLevel.PageFit : SpecialZoomLevel.ActualSize}
                initialPage={page - 1}
                onPageChange={handlePageChange}
                onDocumentLoad={onDocumentLoadSuccess}
                pageLayout={pageLayout}
              />
            </div>
            {!isMobile && (
              <div className="menu-item">
                <button onClick={pageNavigationPluginInstance.jumpToPreviousPage}>
                  <img src={ChevronIcon} alt="Chevron" /> Prev
                </button>
                <SearchBar
                  ref={searchBarRef}
                  showSearchBar={showSearchBar}
                  searchPluginInstance={searchPluginInstance}
                />
                <p>
                  Page <span id="pageNumber">{page}</span> of {numPages}
                </p>
                <button onClick={pageNavigationPluginInstance.jumpToNextPage} className="next">
                  Next
                  <img src={ChevronIcon} alt="Chevron" />
                </button>
              </div>
            )}
          </div>
        </Worker>
        </>
      );
    } else if (videoExtensions.includes(fileExtension)) {
      let videoType = `video/${fileExtension}`;
      if (fileExtension === 'mov') {
        videoType = 'video/mp4'
      }
    
      return (
        <div className="page">
          <video className="images-and-video" style={{ zIndex: 10, position: 'relative' }} ref={videoRef} controls playsInline width="90%" height="auto">
            <source src={decodeURI(modifiedUrl)} type={videoType} />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }
     else if (documentExtensions.includes(fileExtension)) {
      return (
        <div className="document-media-container">
          <MicrosoftViewer modifiedUrl={modifiedUrl} page={page} />
        </div>
      )
    } else if (imageExtensions.includes(fileExtension)) {
      return (
        <div className="document-media-container">
          <img className="images-and-video" src={decodeURI(modifiedUrl)} alt="Document" />
        </div>
      )
    } else if (['csv'].includes(fileExtension)) {
      return (
        <CsvViewer csvUrl={modifiedUrl} searchPluginInstance={searchPluginInstance}  />
      )
    } else if (['txt'].includes(fileExtension)) {
      return (
        <TxtViewer url={modifiedUrl} />
      )
    } else if (['html', 'htm'].includes(fileExtension)) {
      return (
        <HtmlViewer url={modifiedUrl} />
      )
    } else {
      return <div>Unsupported file type: {fileExtension}</div>;
    }
  };

  return (
    <div className="App">
      {renderViewer()}
    </div>
  );
}

export default App;
