import React, { forwardRef, useEffect, useState } from 'react';
import '@react-pdf-viewer/search/lib/styles/index.css';

const SearchBar = forwardRef(({ searchPluginInstance, showSearchBar }, ref) => {
  const { Search } = searchPluginInstance;
  const [searchPerformed, setSearchPerformed] = useState(false);

  useEffect(() => {
    if (showSearchBar && ref && ref.current) {
      ref.current.focus();
    }
  }, [showSearchBar, ref]);

  return (
    showSearchBar && (
      <Search>
        {(renderSearchProps) => (
          <div className="search-bar">
            <div className="searchContainer">
              <input
                type="text"
                placeholder="Enter text to search"
                value={renderSearchProps.keyword}
                onChange={(e) => {
                  const value = e.target.value;
                  renderSearchProps.setKeyword(value);

                  if (value === '') {
                    // Clear the search results and reset the searchPerformed state
                    renderSearchProps.clearKeyword();
                    setSearchPerformed(false);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && renderSearchProps.keyword !== '') {
                    renderSearchProps.search();
                    setSearchPerformed(true);
                  }
                }}
                ref={ref}
              />
              <button
                onClick={() => {
                  if (renderSearchProps.keyword !== '') {
                    renderSearchProps.search();
                    setSearchPerformed(true);
                  }
                }}
              >
                Search
              </button>
            </div>
            {searchPerformed && (
              <div className="nextTap">
                <button onClick={renderSearchProps.jumpToPreviousMatch}>
                  &#9650;
                </button>
                <button onClick={renderSearchProps.jumpToNextMatch}>
                  &#9660;
                </button>
              </div>
            )}
          </div>
        )}
      </Search>
    )
  );
});

export default SearchBar;
